<template>
  <div class="min-h-full flex items-center justify-center py-12 w-full">
    <div class="flex items-center justify-start space-x-8 pr-8">
      <img class="w-0 md:w-2/3 rounded-r-full" src="/hob_login.jpg" />
      <div>
        <login-logo :show-text="true" class="mx-auto"/>
        <form v-if="!twoFactor" @submit.prevent="submit" class="mt-8 space-y-6">
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input v-model="email" id="email" name="email" type="text"  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Email" />
<!--              <input v-model="email" id="email-address" name="email" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Email address" />-->
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Password" />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm">
              <router-link to="/forgot-password" class="font-medium text-base-600 hover:text-base-500">
                Forgot your password?
              </router-link>
            </div>
          </div>

          <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-base-500 group-hover:text-base-400" aria-hidden="true" />
            </span>
              Log in
            </button>
          </div>
          <error-label v-if="errors.length" :errors="errors"></error-label>
        </form>
        <div v-if="twoFactor && toggleAuth && secret" class="flex flex-col items-center justify-center">
          <p class="col-24 text-sm mb-5">
            Connect an authenticator app that generates verifcation
            codes. You can use the codes when we need to verify your
            identity.
            <ol class="list-decimal ml-5 mt-2">
              <li>
                Download and install an authenticator app on your
                mobile device.
              </li>
              <li>Use the app to scan this QR code</li>
              <li>Enter the code generated by app.</li>
            </ol>
          </p>
          <qr-code :text="linkAuth" size="128" error-level="H"></qr-code>
        </div>
        <div v-else-if="twoFactor && !toggleAuth && secret" class="flex flex-col items-start justify-center">
          <p class="text-sm mb-10">
            On your mobile device, go to the authenticator app and enter this key.
          </p>
          <p class="text-sm text-gray-400 text-left">Key</p>
          <p>{{ secret }}</p>
        </div>
        <div v-else-if="twoFactor && !secret" class="flex flex-col items-start justify-center">
          <p class="text-sm">
            To keep your account secure, we verify your identity. Enter the code generated by your authenticator app.
          </p>
        </div>
        <form v-if="twoFactor" @submit.prevent="submitTwoFactor" class="mt-8 space-y-6">
          <div>
            <label for="verification-code" class="text-sm text-gray-400">Verification code</label>
            <input v-model="toptCode" id="verification-code" type="text" class="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-base-500 focus:border-base-500 focus:z-10 sm:text-sm" placeholder="Verification code" />
          </div>
          <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
              {{ secret ? "Connect" : "Verify" }}
            </button>
          </div>
          <div v-if="secret" class="text-sm">
            <p class="cursor-pointer font-medium text-base-600 hover:text-base-500" @click="toggleAuth = !toggleAuth">
              {{ toggleAuth ? "I Can't Scan the QR Code?" : "I Can Scan QR Code?" }}
            </p>
          </div>
        </form>
      </div>
    </div>
    <error-notification :show="error != null" :message="error" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { LockClosedIcon } from '@heroicons/vue/solid'
import { required, minLength, email } from 'vuelidate/lib/validators'
import errorLabel from '../components/errorLabel'
import {mapState, mapActions} from 'vuex'
import errorNotification from "@/components/ui/ErrorNotification";
import loginLogo from "@/components/ui/LoginLogo";
import {EyeIcon} from "@heroicons/vue/solid"

export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapState('auth', ['user']),
    errors() {
      let output = [];
      this.v$.$errors.forEach(e=>{
        output.push(e.$message)
      })
      return output;
    }
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
      twoFactor: false,
      linkAuth: '',
      secret: '',
      toggleAuth: true,
      toptCode: '',
      id: null,
    }
  },
  components: {
    LockClosedIcon,
    errorLabel,
    errorNotification,
    loginLogo
  },
  validations() {
    return {
        email: {
          required: {
            $validator: required,
            $message: 'Email is required'
          },
          email: {
            $validator: email,
            $message: 'Email is not valid'
          },
        },
        password: {
          required: {
            $validator: required,
            $message: 'Password is required'
          },
          minLength: {
            $validator: minLength(8),
            $message: 'Your password must be at least 8 characters'
          }
        },
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'loginTopt']),
    async submit() {
      this.v$.$touch();
      if(!this.v$.$invalid) {
        try {
          let res = await this.login({ email: this.email, password: this.password });
          if (res?.data?.email) {
            this.$router.push('/')
          } else {
            this.id = res.id;
            this.twoFactor = true;
            if (!!res.topt_secret) {
              this.linkAuth = res.topt_path;
              this.secret = res.topt_secret;
            }
          }
        } catch (e) {
          let msg = e.response.status == 412 ? "User not verified" : e.response.data
          this.error = msg

          setTimeout(()=>{
            this.error = null
          }, 3000)
        }
      }
    },
    async submitTwoFactor() {
      try {
        let res = await this.loginTopt({ id: this.id, topt_code: this.toptCode});
        this.$router.push('/')
      } catch (e) {
        let msg = e.response.status == 412 ? "User not verified" : e.response.data
        this.error = msg

        setTimeout(()=>{
          this.error = null
        }, 3000)
      }
    },
  }
}
</script>
